import axios from 'axios';

import * as actionTypes from './actionTypes';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (token, userId) => {
  console.log(token);
  console.log(userId);
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('userId');
  localStorage.removeItem('refreshToken');
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const checkAuthTimeout = (expirationTime, refreshToken) => {
  return dispatch => {
    setTimeout(() => {
      const params = {
        grant_type: 'refresh_token',
        refresh_token: refreshToken
      };
      axios.post('https://securetoken.googleapis.com/v1/token?key=AIzaSyAbL2XAGUB_KMVaMr4yWlrTC96qVcL6Kx0', params)
        .then(response => {
          const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 1000);
          localStorage.setItem('token', response.data.id_token);
          localStorage.setItem('expirationDate', expirationDate);
          localStorage.setItem('refreshToken', response.data.refresh_token);
          dispatch(authSuccess(response.data.id_token, localStorage.getItem('userId')));
          dispatch(checkAuthTimeout(response.data.expirationDate, response.data.refresh_token));
        })
    }, expirationTime * 1000);
  };
};

export const auth = (email, password, isSignup) => {
  return dispatch => {
    dispatch(authStart());
    const authData = {
      email: email,
      password: password,
      returnSecureToken: true
    };
    let url = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=AIzaSyAbL2XAGUB_KMVaMr4yWlrTC96qVcL6Kx0';
    if (!isSignup) {
      url = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=AIzaSyAbL2XAGUB_KMVaMr4yWlrTC96qVcL6Kx0';
    }
    axios.post(url, authData)
      .then(response => {
        console.log(response.data.expiresIn);
        const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
        localStorage.setItem('token', response.data.idToken);
        localStorage.setItem('expirationDate', expirationDate);
        localStorage.setItem('userId', response.data.localId);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        dispatch(authSuccess(response.data.idToken, response.data.localId));
        dispatch(checkAuthTimeout(response.data.expiresIn, response.data.refreshToken));
      })
      .catch(err => {
        dispatch(authFail(err.response.data.error));
      });
  }
};

export const authGoogle = (token, userId) => {
  return dispatch => {
    dispatch(authSuccess(token, userId));
  }
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        const userId = localStorage.getItem('userId');
        dispatch(authSuccess(token, userId));
        // dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ), localStorage.getItem('refreshToken'));
      }
    }
  };
};