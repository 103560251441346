import React, {useEffect, useRef, useState} from 'react';
import axios from "../../../../../../axios-config";
import './Rating.sass'

const Rating = props => {
  const {loadRating} = props;
  const [feedback, setFeedback] = useState(true);
  const [rating, setRating] = useState();
  const [table, setTable] = useState();

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      window.scrollTo(0, 0);
    }
  });

  useEffect(() => {
    let queryParams = '?&orderBy="locationId"&equalTo="' + props.locationId + '"';
    axios.get('/feedback.json' + queryParams)
      .then(response => {
        const fields = [
          'Чи є там освітлення?',
          'Чи є там трафік?',
          'Чи є вхід платним?',
          'Чи шумно там?',
          'Чи людно там?',
          'Для якої пробіжки це місце найкраще підходить?',
          'Яке там покриття?',
          'Чи є де припаркувати авто неподалік?',
          'Чи є якесь місце щоб перекусити неподалік?',
          'Чи може щось перервати або призупинити Вашу пробіжку? Наприклад, світлофори',
          'Чи безпечно там бігати?',
          'Чи часто можна зустріти там агресивних собак?',
          'Чи є поблизу де придбати воду або вільний доступ до питної води (природнє або рукотворне джерело)?',
          'Чи є там спортивний майданчик?',
          'Чи чисте там повітря?',
          'Чи багато підйомів і спусків доведеться зустріти протягом тренування? Якщо так, чи можливо уникнути гірки?'
        ];
        const keys = [
          'light',
          'traffic',
          'cost',
          'noise',
          'crowded',
          'long',
          'surface',
          'parking',
          'cafe',
          'interruption',
          'safety',
          'dogs',
          'water',
          'gym',
          'pollution',
          'hills'
        ];
        let results = [];
        for (let i = 0; i < keys.length; i++) {
          results.push([]);
        }
        if (Object.keys(response.data).length === 0) {
          setFeedback(false)
        }
        let item = [];
        for (let feedback in response.data) {
          item.push(response.data[feedback].rating);
          for (let key in keys) {
            let resultField = [];
            resultField.push(response.data[feedback][keys[key]]);
            results[key].push(resultField);
          }
        }
        let sortedInformation = [];
        let overall = [];
        for (let result in results) {
          let field = [];
          let fieldOverall = [];
          for (let item in results[result]) {
            if (field.length > 0 && results[result][item].toString() !== '') {
              fieldOverall[0].overall += 1;
              let fieldExists = false;
              for (let i in field) {
                if (field[i].value === results[result][item].toString()) {
                  fieldExists = true;
                  const quantity = field[i].quantity;
                  field[i] = {
                    ...field[i],
                    ...{quantity: quantity + 1}
                  };
                }
              }
              if (!fieldExists) {
                console.log(results[result][item].toString());
                const temp = results[result][item].toString();
                const obj = {
                  value: temp,
                  quantity: 1
                };
                if (temp !== '') {
                  field.push(obj);
                }
              }
            } else {
              console.log(results[result][item].toString());
              const temp = results[result][item].toString();
              const obj = {
                value: temp,
                quantity: 1
              };
              if (temp !== '') {
                field.push(obj);
                fieldOverall.push({overall: 1})
              }
            }
          }
          sortedInformation.push(field);
          overall.push(fieldOverall)
        }
        let ratingSum = 0;
        for (let i in item) {
          ratingSum = ratingSum + parseInt(item[i])
        }
        console.log(sortedInformation);
        setRating((ratingSum / item.length).toFixed(2));
        setTable(
          fields.map((field, index) => (
            sortedInformation[index].length > 0 ?
                <div className="rating-field">
                  <h4>{field}</h4>
                  {sortedInformation[index].map(field => (
                    <div>
                      <span>{(field.quantity/(overall[index][0].overall/100)).toFixed(2)}% людей відповіли що</span>
                      {field.value}
                      <div className="progressbar" style={{width: (field.quantity/(overall[index][0].overall/100)).toFixed(2) + '%'}}></div>
                    </div>
                  ))}
                </div>
                : null
          ))
        );
      })
      .catch(err => {
        console.log(err);
      });
  }, [loadRating]);

  return (
    <div className="rating">
      {feedback ?
        <div>
          <div className="rating-display">
            <i className="fa fa-star"></i> {rating}
          </div>
          {table}
        </div>
        : 'Немає рейтингу'}
    </div>
  );
};

export default Rating;