import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import './Navigation.sass'
import * as actions from "../../store/actions";
import connect from "react-redux/es/connect/connect";
import { ReactComponent as Home } from '../../assets/images/nav/real-estate.svg';
import { ReactComponent as City } from '../../assets/images/nav/buildings.svg';

const Navigation = props => {
  const [menuOpened, setMenuOpened] = useState();
  return (
    <header className="main-header">
      <nav>
        <div id="logo">
          <NavLink to="/" exact>Where To Run</NavLink>
        </div>
        <ul className={menuOpened ? 'opened-menu' : null}>
          <li onClick={() => {setMenuOpened(false)}}>
            <NavLink to="/" exact>
              <Home /> Головна
            </NavLink>
          </li>
          <li onClick={() => {setMenuOpened(false)}}>
            <NavLink to="/cities">
              <City /> Міста
            </NavLink>
          </li>
          {props.isAuthenticated ?
            <li className="auth-button">
              <NavLink to="/logout">Вийти</NavLink>
            </li> :
            <li className="auth-button" onClick={() => {setMenuOpened(false)}}>
              <NavLink to="/auth">Увійти</NavLink>
            </li>
          }
        </ul>
        <button className="mobile-toggler"
                onClick={() => {
                  if (!menuOpened) {
                    setMenuOpened(true)
                  } else {
                    setMenuOpened(false)
                  }
                }}>
          <i className="fa fa-bars"></i>
        </button>
      </nav>
    </header>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
