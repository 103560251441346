import React, {useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import CreateLocation from './CreateLocation/CreateLocation';
import { Redirect } from 'react-router-dom';

import AddFeedback from './AddFeedback/AddFeedback';
import MapContainer from "../../../../shared/MapContainer/MapContainer";
import axios from "../../../../axios-config";
import Rating from "./AddFeedback/Rating/Rating";
import LocationIcon from "../../../../assets/images/icons/location.svg";
import ClockIcon from "../../../../assets/images/icons/clock.svg";

const Location = props => {
  const { onTryAutoSignup } = props;

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      window.scrollTo(0, 0);
    }
  });

  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  const [editor, showEditor] = useState(false);
  const [deleting, setDeleting] = useState(false);

  let location;
  let locationData;

  const deleteLocation = () => {
    props.onDeleteLocation(props.match.url.split('/')[2].toLowerCase(), props.match.params.locationId);
  };

  const loadLocation = () => {
    if (props.locations[0]) {
      for (let i = 0; i < props.locations.length; i++) {
        if (props.locations[i].locationId === props.match.params.locationId) {
          location = props.locations[i]
        }
      }
    }
    if (location) {
      const setNewId = (id) => {
        window.location.href = props.match.url + '#' + id
      };
      const editLocation = () => {
        props.onResetLocations();
        showEditor(true);
      };
      const reloadLocation = () => {
        showEditor(false);
        if (!window.location.href.split('#')[1]) {
          props.onFetchLocations(props.match.url.split('/')[2].toLowerCase(), props.match.params.userId);
        }
      };
      locationData = (
        <div>
          {editor ?
            <CreateLocation
              locationItem={location}
              deleteCity={props.match.url.split('/')[2].toLowerCase()}
              deleteId={props.match.params.locationId}
              setNewId={(id) => setNewId(id)}
              onFinished={reloadLocation}/> :
            <div>
              <div className="item-intro">
                <div className="item-cover">
                  <img src={location.image} alt={location.name}/>
                </div>
                <div className="item-map">
                  <MapContainer lat={location.lat} lng={location.lng}/>
                </div>
              </div>
              {window.location.href.split('#')[1] ?
                <Redirect to={'/cities/' +
                props.match.url.split('/')[2] + '/location/' + window.location.href.split('#')[1] }/> : null}
              <h2 className="item-title">
                {location.name}
                <div>
                  <span className="item-title-location">
                    <img src={LocationIcon} alt="" />
                    {location.location}
                  </span>
                  <span className="item-title-category">
                    <img src={ClockIcon} alt="" />
                    {location.category === 'stadium' ? 'Стадіон' : null}
                    {location.category === 'park' ? 'Парк' : null}
                    {location.category === 'forest' ? 'Ліс' : null}
                    {location.category === 'coast' ? 'Набережна' : null}
                    {location.category === 'other' ? 'Інше' : null}
                  </span>
                </div>
              </h2>
              <Rating locationId={location.locationId}/>
              {location.userId === props.userId ?
                <div className="item-config">
                  <button className="btn" onClick={() => {deleteLocation(false); setDeleting(true)}}>Видалити</button>
                  <button className="btn" onClick={editLocation}>Редагувати</button>
                </div> : null
              }
              <AddFeedback url={ props.match.url } locationId={ location.locationId } userId={props.userId}/>
            </div>
          }
        </div>
      );
    }
  };

  loadLocation();

  if (!location) {
    locationData = 'Loading...';
    props.onFetchLocations(props.match.url.split('/')[2].toLowerCase(), props.match.params.locationId);
  }

  if (!props.locations.loading && location) {
    loadLocation()
  }

  return(
    <div>
      {props.loading ? 'Loading...' :
        <div>
          {deleting && props.deleted ?
            <Redirect to={'/cities/' + props.match.url.split('/')[2]}/>
            : locationData}
        </div>
      }
    </div>
  )
};

const mapStateToProps = state => {
  return {
    locations: state.locations.locations,
    loading: state.locations.loading,
    deleted: state.locations.deleted,
    userId: state.auth.userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchLocations: (city, locationId) =>
      dispatch(actions.fetchLocations(city, locationId)),
    onDeleteLocation: (city, locationId) =>
      dispatch(actions.deleteLocation(city, locationId)),
    onResetLocations: () => dispatch(actions.resetLocations()),
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Location);