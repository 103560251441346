import React, {useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import './Welcome.sass';
import {getCityImage} from "../../shared/utility";
import Banner from "../../assets/images/banner.jpg";

const Welcome = props => {
  const { onTryAutoSignup } = props;

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      window.scrollTo(0, 0);
    }
  });

  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  return (
    <div className="welcome">
      <div className="welcome-intro" style={{backgroundImage: "url(" + Banner + ")"}}>
        <h1>Where To Run</h1>
        <h2>Знайди чудове місце для бігу</h2>
      </div>
      <h3>
        Найпопулярніші міста
        <button className="btn more-cities"><Link to="/cities">Всі міста</Link></button>
      </h3>
      <div className="popular-cities">
        <Link to="/cities/lviv">
          <div className="intro" style={{backgroundImage: "url(" + getCityImage('lviv') + ")"}}>
            <h2>Lviv</h2>
          </div>
        </Link>
        <Link to="/cities/kyiv">
          <div className="intro" style={{backgroundImage: "url(" + getCityImage('kyiv') + ")"}}>
            <h2>Kyiv</h2>
          </div>
        </Link>
        <Link to="/cities/odessa">
          <div className="intro" style={{backgroundImage: "url(" + getCityImage('odessa') + ")"}}>
            <h2>Odessa</h2>
          </div>
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);