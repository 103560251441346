import React from 'react';
import './Footer.sass'



const Footer = () => {
  return (
    <div className="footer">
      <h2>Where To Run</h2>
      <p>© {(new Date().getFullYear())} Where To Run. All rights reserved.</p>
    </div>
  );
};

export default Footer;