import React, {useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './City.sass'
import * as actions from '../../../store/actions/index';
import {getCityImage} from "../../../shared/utility";

const City = props => {
  const { onFetchEvents } = props;
  const { onFetchLocations } = props;

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      window.scrollTo(0, 0);
    }
  });

  useEffect(() => {
    onFetchEvents(props.match.params.name.toLowerCase());
    props.onResetEvents();
  }, [onFetchEvents]);

  useEffect(() => {
    onFetchLocations(props.match.params.name.toLowerCase());
  }, [onFetchLocations]);

  let events = 'Loading...';
  if (!props.events.loading) {
    const eventsArray = {...props.events.events};
    let currentEvents = [];
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    const formatDate = date => {
      const unformattedDate = date.split("/");
      const formattedDate = new Date(+unformattedDate[2], unformattedDate[1] - 1, +unformattedDate[0]);
      return (formattedDate);
    };

    for (let event in eventsArray) {
      if (formatDate(eventsArray[event].date) > currentDate) {
        currentEvents.push(eventsArray[event]);
      }
    }

    let nearestEvent;
    let nearestEventIndex;
    let lowestDiff = 0;
    let sortedEvents = [];

    const sortEvents = () => {
      const currentEventsLength = currentEvents.length;
      for (let i = 0; i <= currentEventsLength - 1; i++) {
        lowestDiff = 0;
        for (let event in currentEvents) {
          const diff = Math.abs(formatDate(currentEvents[event].date) - currentDate);
          console.log(diff);
          if (lowestDiff === 0) {
            lowestDiff = diff;
            nearestEvent = currentEvents[event];
            nearestEventIndex = event
          } else if (lowestDiff > diff) {
            lowestDiff = diff;
            nearestEvent = currentEvents[event];
            nearestEventIndex = event
          }
        }
        sortedEvents.push(nearestEvent);
        currentEvents.splice(nearestEventIndex, 1);
      }
    };

    sortEvents();

    events = sortedEvents.map(event => (
      <Link key={event.eventId} to={'/cities/'+ props.match.params.name + '/event/' + event.eventId}>
        <div className="city-item">
          <h4>{event.name}</h4>
          <div className="city-item-img">
            <img src={event.image} />
            <span className="city-item-category">{event.date}</span>
          </div>
        </div>
      </Link>
    ))
  }

  let locations = 'Loading...';
  if (!props.locations.loading) {
    locations = props.locations.locations.map(location => (
      <Link key={location.name} to={'/cities/'+ props.match.params.name + '/location/' + location.locationId}>
        <div className="city-item">
          <h4>{location.name}</h4>
          <div className="city-item-img">
            <img src={location.image} />
            <span className="city-item-category">
              {location.category === 'stadium' ? 'Стадіон' : null}
              {location.category === 'park' ? 'Парк' : null}
              {location.category === 'forest' ? 'Ліс' : null}
              {location.category === 'coast' ? 'Набережна' : null}
              {location.category === 'other' ? 'Інше' : null}
            </span>
          </div>
        </div>
      </Link>
    ))
  }

  return(
    <div>
      <div className="intro"
           style={{backgroundImage: "url(" + getCityImage(props.match.params.name.toLowerCase()) + ")"}}>
        <h2>
          {props.match.params.name}
        </h2>
      </div>
      <h3>
        Місця для пробіжки
        <Link className="btn" to={'/cities/'+ props.match.params.name + '/create-location'}>Створити нове місце</Link>
      </h3>
      <div className="city-items-container">{locations}</div>
      <h3>
        Події
        <Link className="btn" to={'/cities/'+ props.match.params.name + '/create-event'}>Створити нову подію</Link>
      </h3>
      <div className="city-items-container">{events}</div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    events: state.events,
    locations: state.locations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchEvents: (city) =>
      dispatch(actions.fetchEvents(city)),
    onResetEvents: () => dispatch(actions.resetEvents()),
    onFetchLocations: (city) =>
      dispatch(actions.fetchLocations(city))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(City);