import React, {useEffect, useRef, useState} from 'react';
import * as actions from '../../../../../store/actions/index';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import axios from "../../../../../axios-config";
import PlacesAutocomplete from "../../../../../shared/PlaceAutocomplete/PlaceAutocomplete";
import ImageUpload from "../../../../../shared/ImageUpload/ImageUpload";

const CreateLocation = props => {
  const { onTryAutoSignup } = props;

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      window.scrollTo(0, 0);
    }
  });
  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  let location;

  let editing = false;
  const [deletingOld, setDeletingOld] = useState(false);

  if (props.locationItem) {
    location = props.locationItem;
    editing = true;
  } else {
    location = {
      name: '',
      city: props.match.url.split('/')[2].toLowerCase(),
      location: '',
      lat: '',
      lng: '',
      category: '',
      userId: '',
      image: ''
    }
  }

  const [form, setForm] = useState({
    name: {
      order: 1,
      type: 'text',
      placeholder: 'Назва',
      value: location.name,
      message: ''
    },
    city: {
      order: 2,
      type: 'hidden',
      placeholder: 'City',
      value: location.city,
      message: ''
    },
    location: {
      order: 3,
      type: 'location',
      placeholder: 'Location',
      value: location.location,
      message: ''
    },
    lat: {
      order: 4,
      type: 'hidden',
      value: location.lat,
      message: ''
    },
    lng: {
      order: 5,
      type: 'hidden',
      value: location.lng,
      message: ''
    },
    category: {
      order: 6,
      type: 'category',
      placeholder: 'Category',
      value: location.category,
      message: ''
    },
    image: {
      order: 7,
      type: 'imagePicker',
      value: location.image,
      message: ''
    }
  });

  const [imageSrc, setImageSrc] = useState(location.image);
  const mandatoryFields = ['name', 'location'];

  const locationHandler = (event) => {
    event.preventDefault();

    let valid = true;
    let errorFields = [];
    const validateForm = (formData) => {
      for (let field in mandatoryFields) {
        if (formData[mandatoryFields[field]].length === 0) {
          valid = false;
          const emptyField = mandatoryFields[field].toString();
          errorFields.push(emptyField);
        }
      }
    };

    const createNewLocation = () => {
      const formData = {};
      for (let formElementIdentifier in form) {
        formData[formElementIdentifier] = form[formElementIdentifier].value;
      }
      validateForm(formData);
      if (valid) {
        formData['userId'] = props.userId;
        formData['locationId'] = formData['name'].toLowerCase().split(' ').join('-').split('#').join('').split('/').join('');
        formData['rawId'] = formData['locationId'];
        formData['image'] = imageSrc;
        axios.get('/locations/' + formData['city'].toLowerCase() +
          '.json?&orderBy="rawId"&equalTo="' + formData['locationId'] + '"')
          .then(res => {
            if (Object.keys(res.data).length > 0) {
              let number = 2;
              for (let event in res.data) {
                if (res.data[event]['numberId'] && res.data[event]['numberId'] === number) {
                  number++
                }
              }
              formData['numberId'] = number;
              formData['locationId'] = formData['rawId'] + number;
            }

            const newLocation = {
              locationData: formData
            };

            if (props.locationItem && props.locationItem.locationId !== formData['locationId']) {
              props.setNewId(formData['locationId']);
            }

            props.onCreateLocation(newLocation);
          })
          .catch(err => {
            console.log(err)
          });
      } else {
        let updatedForm = {
          ...form
        };
        for (let field in mandatoryFields) {
          console.log(mandatoryFields[field]);
          const updatedField = {
            ...form[mandatoryFields[field]],
            ...{message: ''}
          };
          updatedForm = {
            ...updatedForm,
            ...{[mandatoryFields[field]]: updatedField}
          };
        }
        for (let field in errorFields) {
          console.log(errorFields[field]);
          const updatedField = {
            ...form[errorFields[field]],
            ...{message: 'Не може бути пустим'}
          };
          updatedForm = {
            ...updatedForm,
            ...{[errorFields[field]]: updatedField}
          };
        }
        setForm(updatedForm);
      }
    };

    if (props.locationItem) {
      let queryParams = '';
      if (props.deleteId) {
        queryParams = '?&orderBy="locationId"&equalTo="' + props.deleteId + '"';
      }
      setDeletingOld(true);
      axios.get( '/locations/' + props.deleteCity + '.json' + queryParams)
        .then( response => {
          axios.delete( '/locations/' + props.deleteCity + '/' + Object.keys(response.data)[0] + '.json')
            .then( () => {
              createNewLocation();
            } )
            .catch( error => {
              console.log(error)
            } );
        } )
        .catch( err => {
          console.log(err)
        } );
    } else {
      createNewLocation();
    }
  };

  const updateValue = (value, inputIdentifier) => {
    const updatedFormElement = {
      ...form[inputIdentifier],
      ...{value: value}
    };
    const updatedLocationForm = {
      ...form,
      ...{[inputIdentifier]: updatedFormElement}
    };
    setForm(updatedLocationForm);
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    updateValue(event.target.value, inputIdentifier);
    for (let item in mandatoryFields) {
      if (mandatoryFields[item] === inputIdentifier) {
        const eventValue = event.target.value;
        let updatedFormElement;
        if (eventValue.replace(/\s/g,'').length > 0) {
          updatedFormElement = {
            ...form[inputIdentifier],
            ...{message: ''},
            ...{value: eventValue}
          };
        } else {
          updatedFormElement = {
            ...form[inputIdentifier],
            ...{message: 'Не може бути пустим'},
            ...{value: eventValue}
          };
        }
        const updatedLocationForm = {
          ...form,
          ...{[inputIdentifier]: updatedFormElement}
        };
        setForm(updatedLocationForm);
      }
    }
  };

  const updateLocation = (location, lat, lng) => {
    let updatedLocation = {
      ...form['location'],
      ...{value: location},
      ...{message: ''}
    };
    if (location.replace(/\s/g,'').length === 0) {
      updatedLocation = {
        ...form['location'],
        ...{value: location},
        ...{message: 'Не може бути пустим'}
      };
    }
    const updatedLat = {
      ...form['lat'],
      ...{value: lat}
    };
    const updatedLng = {
      ...form['lng'],
      ...{value: lng}
    };
    const updatedLocationForm = {
      ...form,
      ...{['location']: updatedLocation},
      ...{['lat']: updatedLat},
      ...{['lng']: updatedLng}
    };
    setForm(updatedLocationForm);
  };

  const formElementsArray = [];
  for (let key in form) {
    formElementsArray.push({
      id: key,
      config: form[key]
    });
  }

  const [showLocationEditor, setShowLocationEditor] = useState(false);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const fields = (
    formElementsArray.map(formElement => (
      <div key={formElement.id} style={{order: formElement.config.order, height: formElement.config.type === 'hidden' ? 0 : 'auto'}}>
        {formElement.config.type === 'location' ?
          <div>
            {editing ?
              <div>
                {showLocationEditor ?
                  <div>
                    <PlacesAutocomplete value={formElement.config.value}
                                        update={(location, lat, lng) => updateLocation(location, lat, lng)}/>
                    <button className="btn btn-secondary" type={"button"} onClick={() => {
                      setShowLocationEditor(false);
                      updateLocation(location.location, location.lat, location.lng)
                    }}>Cancel</button>
                  </div> :
                  <div>
                    {location.location}
                    <button className="btn btn-secondary" type={"button"} onClick={() => (setShowLocationEditor(true))}>Edit Location</button>
                  </div>
                }
              </div> :
              <div>
                <PlacesAutocomplete value={formElement.config.value}
                                    update={(location, lat, lng) => updateLocation(location, lat, lng)}/>
                <p>{formElement.config.message}</p>
              </div>
            }
          </div> : null
        }
        {formElement.config.type === 'text' ||
          formElement.config.type === 'hidden' ?
          <div>
            <input
              key={formElement.id}
              className="input-main"
              type={formElement.config.type}
              placeholder={formElement.config.placeholder}
              value={formElement.config.value}
              onChange={event => inputChangedHandler(event, formElement.id)}/>
            <p>{formElement.config.message}</p>
          </div>: null}
        {formElement.config.type === 'imagePicker' ?
          <div>
            {editing ?
              <div>
                {showImageEditor ?
                  <div>
                    {imageUploading ? 'Uploading Image...' : null}
                    <ImageUpload
                      imgSrc={(src) => {setImageSrc(src)}}
                      imageUploading={(val) => setImageUploading(val)}
                      isUploading={imageUploading}
                    />
                    <button className="btn btn-secondary" type={"button"} onClick={() => {
                      setShowImageEditor(false);
                      setImageSrc(location.image)
                    }}>Cancel</button>
                  </div> :
                  <div>
                    <img className="image-preview" src={location.image} alt={location.name}/>
                    <br />
                    <button className="btn btn-secondary"
                            type={"button"}
                            onClick={() => (setShowImageEditor(true))}>
                      Edit Image
                    </button>
                  </div>
                }
              </div> :
              <div>
                {imageUploading ? 'Uploading Image...' : null}
                <ImageUpload
                  imgSrc={(src) => {setImageSrc(src)}}
                  imageUploading={(val) => setImageUploading(val)}
                  isUploading={imageUploading}
                />
              </div>
            }
          </div> : null }
        {formElement.config.type === 'category' ?
          <div>
            <select className="input-main" onChange={event => inputChangedHandler(event, formElement.id)}>
              <option value="" disabled selected>Оберіть категорію</option>
              <option value="stadium">Стадіон</option>
              <option value="park">Парк</option>
              <option value="forest">Ліс</option>
              <option value="coast">Набережна</option>
              <option value="other">Інше</option>
            </select>
            <p>{formElement.config.message}</p>
          </div>  : null }
      </div>
    ))
  );

  if (!props.isAuthenticated) {
    props.onSetAuthRedirectPath(props.match.url)
  }

  return (
    <div className="create-page">
      {props.loading ? 'Loading...' :
        <div>
          <h3>{props.locationItem ? <span>Редагувати</span> : <span>Створити</span>} місце</h3>
          {props.isAuthenticated ?
            <form onSubmit={locationHandler}>
              {fields}
              {props.locationItem ?
                <button style={{order: 8}} className="btn btn-edit" disabled={deletingOld ? 'disabled' : null || imageUploading}>
                  {deletingOld ? 'Loading...' : 'Редагувати'}
                </button>
                : <button style={{order: 8}} className="btn" disabled={imageUploading}>Створити</button>}
            </form> :
            <div>
              Щоб створити місце необхідно <Link to="/auth">увійти</Link>
            </div>
          }
          {props.created && editing ? props.onFinished() : null}
          {props.created && !editing ?
            <div>
              <Redirect to={'/cities/' + props.match.url.split('/')[2]}/>
            </div>
            : null}
        </div>
      }
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    userId: state.auth.userId,
    created: state.locations.created,
    loading: state.locations.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCreateLocation: (locationData) =>
      dispatch(actions.createLocation(locationData)),
    onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLocation);