export const CREATE_EVENT_START = 'CREATE_EVENT_START';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAIL = 'CREATE_EVENT_FAIL';
export const DELETE_EVENT_START = 'DELETE_EVENT_START';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const EVENTS_INIT = 'EVENTS_INIT';
export const RESET_EVENTS = 'RESET_EVENTS';

export const FETCH_EVENTS_START = 'FETCH_EVENTS_START';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAIL = 'FETCH_EVENTS_FAIL';

export const CREATE_LOCATION_START = 'CREATE_LOCATION_START';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAIL = 'CREATE_LOCATION_FAIL';
export const DELETE_LOCATION_START = 'DELETE_LOCATION_START';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const LOCATIONS_INIT = 'LOCATIONS_INIT';
export const RESET_LOCATIONS = 'RESET_EVENTS';

export const FETCH_LOCATIONS_START = 'FETCH_LOCATIONS_START';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAIL = 'FETCH_LOCATIONS_FAIL';

export const ADD_FEEDBACK_START = 'ADD_FEEDBACK_START';
export const ADD_FEEDBACK_SUCCESS = 'ADD_FEEDBACK_SUCCESS';
export const ADD_FEEDBACK_FAIL = 'ADD_FEEDBACK_FAIL';
export const FEEDBACK_INIT = 'FEEDBACK_INIT';

export const FETCH_FEEDBACK_START = 'FETCH_FEEDBACK_START';
export const FETCH_FEEDBACK_SUCCESS = 'FETCH_FEEDBACK_SUCCESS';
export const FETCH_FEEDBACK_FAIL = 'FETCH_FEEDBACK_FAIL';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';