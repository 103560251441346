import React, {useState} from 'react';
import {storage} from '../../store/firebase/firebase';
import imageCompression from 'browser-image-compression';

const ImageUpload = props => {

  const [image, setImage] = useState({
    img: null,
    url: ''
  });

  const uploadToServer = img => {
    const uploadTask = storage.ref('images/' + img.name).put(img);
    uploadTask.on('state_changed',
      (snapshot) => {

      },
      (error) => {
        console.log(error)
      },
      () => {
        storage.ref('images').child(img.name).getDownloadURL().then(url => {
          setImage({
            img: img,
            url: url
          });
          props.imgSrc(url);
          props.imageUploading(false);
        })
      });
  };

  async function handleImageUpload(event) {

    const imageFile = event.target.files[0];
    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

      await uploadToServer(compressedFile); // write your own logic
    } catch (error) {
      console.log(error);
    }

  }

  const handleChange = e => {
    if (e.target.files[0]) {
      setImage({
        img: e.target.files[0],
        url: ''
      });
      props.imageUploading(true);
      handleImageUpload(e);
    }
  };

  return (
    <div>
      <input id="file" type="file" name="file" className="image-uploader" disabled={props.isUploading} onChange={handleChange}/>
      <label htmlFor="file" className="input-main"><i className="fa fa-upload"></i> Завантажте картинку</label>
      <br/>
      <img className="image-preview" src={image.url}/>
    </div>
  );
};

export default ImageUpload;