import React, {useEffect, useState} from 'react';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import axios from "../../../axios-config";
import PlacesAutocomplete from "../../../shared/PlaceAutocomplete/PlaceAutocomplete";
import DatePickerContainer from "../../../shared/DatePickerContainer/DatePickerContainer";
import ImageUpload from "../../../shared/ImageUpload/ImageUpload";

const CreateEvent = props => {
  const { onTryAutoSignup } = props;

  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  let event;

  let editing = false;
  const [deletingOld, setDeletingOld] = useState(false);

  if (props.event) {
    event = props.event;
    editing = true;
  } else {
    event = {
      name: '',
      city: props.match.url.split('/')[2].toLowerCase(),
      location: '',
      lat: '',
      lng: '',
      description: '',
      date: '',
      userId: '',
      image: ''
    }
  }

  const [form, setForm] = useState({
    name: {
      order: 1,
      type: 'text',
      placeholder: 'Назва',
      value: event.name,
      message: ''
    },
    city: {
      order: 2,
      type: 'hidden',
      placeholder: 'City',
      value: event.city,
      message: ''
    },
    location: {
      order: 3,
      type: 'location',
      placeholder: 'Location',
      value: event.location,
      message: ''
    },
    lat: {
      order: 4,
      type: 'hidden',
      value: event.lat,
      message: ''
    },
    lng: {
      order: 5,
      type: 'hidden',
      value: event.lng,
      message: ''
    },
    description: {
      order: 6,
      type: 'text',
      placeholder: 'Опис',
      value: event.description,
      message: ''
    },
    date: {
      order: 7,
      type: 'date',
      placeholder: 'Date',
      value: event.date,
      message: ''
    },
    image: {
      order: 8,
      type: 'imagePicker',
      value: event.image,
      message: ''
    }
  });

  const [imageSrc, setImageSrc] = useState(event.image);
  const mandatoryFields = ['name', 'location', 'date'];

  const eventHandler = (event) => {
    event.preventDefault();

    let valid = true;
    let errorFields = [];
    const validateForm = (formData) => {
      for (let field in mandatoryFields) {
        if (formData[mandatoryFields[field]].length === 0) {
          valid = false;
          const emptyField = mandatoryFields[field].toString();
          errorFields.push(emptyField);
        }
      }
    };

    const createNewEvent = () => {
      const formData = {};
      for (let formElementIdentifier in form) {
        formData[formElementIdentifier] = form[formElementIdentifier].value;
      }
      validateForm(formData);
      if (valid) {
        formData['userId'] = props.userId;
        formData['eventId'] = formData['name'].toLowerCase().split(' ').join('-').split('#').join('').split('/').join('');
        formData['rawId'] = formData['eventId'];
        formData['image'] = imageSrc;
        axios.get('/events/' + formData['city'].toLowerCase() +
          '.json?&orderBy="rawId"&equalTo="' + formData['eventId'] + '"')
          .then(res => {
            if (Object.keys(res.data).length > 0) {
              let number = 2;
              for (let event in res.data) {
                if (res.data[event]['numberId'] && res.data[event]['numberId'] === number) {
                  number++
                }
              }
              formData['numberId'] = number;
              formData['eventId'] = formData['rawId'] + number;
            }

            const newEvent = {
              eventData: formData
            };

            if (props.event && props.event.eventId !== formData['eventId']) {
              props.setNewId(formData['eventId']);
            }

            props.onCreateEvent(newEvent);
          })
          .catch(err => {
            console.log(err)
          });
      } else {
        let updatedForm = {
          ...form
        };
        for (let field in mandatoryFields) {
          console.log(mandatoryFields[field]);
          const updatedField = {
            ...form[mandatoryFields[field]],
            ...{message: ''}
          };
          updatedForm = {
            ...updatedForm,
            ...{[mandatoryFields[field]]: updatedField}
          };
        }
        for (let field in errorFields) {
          console.log(errorFields[field]);
          const updatedField = {
            ...form[errorFields[field]],
            ...{message: 'Не може бути пустим'}
          };
          updatedForm = {
            ...updatedForm,
            ...{[errorFields[field]]: updatedField}
          };
        }
        setForm(updatedForm);
      }
    };

    if (props.event) {
      let queryParams = '';
      if (props.deleteId) {
        queryParams = '?&orderBy="eventId"&equalTo="' + props.deleteId + '"';
      }
      setDeletingOld(true);
      axios.get( '/events/' + props.deleteCity + '.json' + queryParams)
        .then( response => {
          axios.delete( '/events/' + props.deleteCity + '/' + Object.keys(response.data)[0] + '.json')
            .then( () => {
              createNewEvent();
            } )
            .catch( error => {
              console.log(error)
            } );
        } )
        .catch( err => {
          console.log(err)
        } );
    } else {
      createNewEvent();
    }
  };

  const updateValue = (value, inputIdentifier) => {
    const updatedFormElement = {
      ...form[inputIdentifier],
      ...{value: value}
    };
    const updatedEventForm = {
      ...form,
      ...{[inputIdentifier]: updatedFormElement}
    };
    setForm(updatedEventForm);
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    updateValue(event.target.value, inputIdentifier);
    for (let item in mandatoryFields) {
      if (mandatoryFields[item] === inputIdentifier) {
        const eventValue = event.target.value;
        let updatedFormElement;
        if (eventValue.replace(/\s/g,'').length > 0) {
          updatedFormElement = {
            ...form[inputIdentifier],
            ...{message: ''},
            ...{value: eventValue}
          };
        } else {
          updatedFormElement = {
            ...form[inputIdentifier],
            ...{message: 'Не може бути пустим'},
            ...{value: eventValue}
          };
        }
        const updatedEventForm = {
          ...form,
          ...{[inputIdentifier]: updatedFormElement}
        };
        setForm(updatedEventForm);
      }
    }
  };

  const updateLocation = (location, lat, lng) => {
    let updatedLocation = {
      ...form['location'],
      ...{value: location},
      ...{message: ''}
    };
    if (location.replace(/\s/g,'').length === 0) {
      updatedLocation = {
        ...form['location'],
        ...{value: location},
        ...{message: 'Не може бути пустим'}
      };
    }
    const updatedLat = {
      ...form['lat'],
      ...{value: lat}
    };
    const updatedLng = {
      ...form['lng'],
      ...{value: lng}
    };
    const updatedEventForm = {
      ...form,
      ...{['location']: updatedLocation},
      ...{['lat']: updatedLat},
      ...{['lng']: updatedLng}
    };
    setForm(updatedEventForm);
  };

  let formElementsArray = [];
  for (let key in form) {
    formElementsArray.push({
      id: key,
      config: form[key],
    });
  }

  const [showLocationEditor, setShowLocationEditor] = useState(false);
  const [showDateEditor, setShowDateEditor] = useState(false);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const fields = (
    formElementsArray.map(formElement => (
      <div style={{display: formElement.config.type === "hidden" ? 'none' : 'block', order: formElement.config.order}}>
        {formElement.config.type === 'location' ?
          <div key={formElement.id} style={{order: formElement.config.order, height: formElement.config.type === 'hidden' ? 0 : 'auto'}}>
            {editing ?
              <div>
                {showLocationEditor ?
                  <div>
                    <PlacesAutocomplete value={formElement.config.value}
                      update={(location, lat, lng) => updateLocation(location, lat, lng)}/>
                    <button type={"button"} onClick={() => {
                      setShowLocationEditor(false);
                      updateLocation(event.location, event.lat, event.lng)
                    }}>Cancel</button>
                  </div> :
                  <div>
                    {event.location}
                    <button className="btn btn-secondary"
                            type={"button"}
                            onClick={() => (setShowLocationEditor(true))}>
                      Edit Location
                    </button>
                  </div>
                }
              </div> :
              <div>
                <PlacesAutocomplete value={formElement.config.value}
                  update={(location, lat, lng) => updateLocation(location, lat, lng)}/>
                <p>{formElement.config.message}</p>
              </div>
            }
          </div> : null
        }
        {formElement.config.type === 'date' ?
          <div>
            {editing ?
              <div>
                {showDateEditor ?
                  <div>
                    <DatePickerContainer update={(value) => updateValue(value, formElement.id)}/>
                    <button type={"button"} onClick={() => {
                      setShowDateEditor(false);
                      updateValue(event.date, formElement.id)
                    }}>Cancel</button>
                  </div> :
                  <div>
                    {event.date}
                    <button className="btn btn-secondary"
                            type={"button"}
                            onClick={() => (setShowDateEditor(true))}>
                      Edit Date
                    </button>
                  </div>
                }
              </div> :
              <div>
                <DatePickerContainer update={(value) => updateValue(value, formElement.id)}/>
                <p>{formElement.config.message}</p>
              </div>
            }
          </div> : null}
        {formElement.config.type === 'text' ||
         formElement.config.type === 'hidden' ?
          <div>
            <input
              className="input-main"
              key={formElement.id}
              type={formElement.config.type}
              placeholder={formElement.config.placeholder}
              value={formElement.config.value}
              onChange={event => inputChangedHandler(event, formElement.id)}/>
            <p>{formElement.config.message}</p>
          </div>: null}
        {formElement.config.type === 'imagePicker' ?
          <div>
            {editing ?
              <div>
                {showImageEditor ?
                  <div>
                    {imageUploading ? 'Uploading Image...' : null}
                    <ImageUpload
                      imgSrc={(src) => {setImageSrc(src)}}
                      imageUploading={(val) => setImageUploading(val)}
                      isUploading={imageUploading}
                    />
                    <button type={"button"} onClick={() => {
                      setShowImageEditor(false);
                      setImageSrc(event.image)
                    }}>Cancel</button>
                  </div> :
                  <div className="image-preview-block">
                    <img className="image-preview" src={event.image} alt={event.name}/>
                    <br />
                    <button className="btn btn-secondary"
                            type={"button"}
                            onClick={() => (setShowImageEditor(true))}>
                      Edit Image
                    </button>
                  </div>
                }
              </div> :
              <div>
                {imageUploading ? 'Uploading Image...' : null}
                <ImageUpload
                  imgSrc={(src) => {setImageSrc(src)}}
                  imageUploading={(val) => setImageUploading(val)}
                  isUploading={imageUploading}
                />
              </div>
            }
          </div> : null }
      </div>
    ))
  );

  if (!props.isAuthenticated) {
    props.onSetAuthRedirectPath(props.match.url)
  }

  return (
    <div className="create-page">
      {props.loading ? 'Loading...' :
        <div>
          <h3>{props.event ? <span>Редагувати</span> : <span>Створити</span>} подію</h3>
          {props.isAuthenticated ?
            <form onSubmit={eventHandler}>
              {fields}
              {props.event ?
                <button style={{order: 9}} className="btn" disabled={deletingOld ? 'disabled' : null || imageUploading}>
                  {deletingOld ? 'Loading...' : 'Редагувати'}
                </button>
                : <button style={{order: 9}} className="btn" disabled={imageUploading}>Створити</button>}
            </form> :
            <div>
              Щоб створити подію необхідно <Link to="/auth">Увійти</Link>
            </div>
          }
          {props.created && editing ?
            props.onFinished() : null}
          {props.created && !editing ?
            <div>
              <Redirect to={'/cities/' + props.match.url.split('/')[2]}/>
            </div>
            : null}
        </div>
      }
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    userId: state.auth.userId,
    created: state.events.created,
    loading: state.events.loading,
    deleted: state.events.deleted
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCreateEvent: (eventData) =>
      dispatch(actions.createEvent(eventData)),
    onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);