import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {getCityImage} from "../../shared/utility";

const Cities = () => {

  const [cities] = useState([
    {
      name: 'Lviv'
    },
    {
      name: 'Kyiv'
    },
    {
      name: 'Odessa'
    }
  ]);

  return(
    <div className="cities">
      {cities.map(city => (
        <Link key={city.name} to={'/cities/' + city.name.toLowerCase()}>
          <div className="intro" style={{backgroundImage: "url(" + getCityImage(city.name.toLowerCase()) + ")"}}>
            <h2>{city.name}</h2>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Cities;