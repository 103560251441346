import React, {useEffect, useState} from 'react';
import * as actions from '../../../../../store/actions/index';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import axios from "../../../../../axios-config";
import './AddFeedback.sass'

const AddFeedback = props => {
  const { onTryAutoSignup } = props;

  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  const [form, setForm] = useState({
    rating: {
      type: 'radio',
      label: 'Рейтинг',
      vars: ['1', '2', '3', '4', '5'],
      value: '',
      required: true,
      order: 1
    },
    light: {
      type: 'radio',
      label: 'Чи є там освітлення?',
      vars: ['Є освітлення', 'Освітлено частково', 'Немає освітлення'],
      value: '',
      required: false,
      order: 2
    },
    traffic: {
      type: 'radio',
      label: 'Чи є там трафік?',
      vars: [
        'Ні, машин немає',
        'Місцями потрібно перетинати проїзну частину',
        'Так, доводиться бігати по/біля доріг по яких їздять машини'
      ],
      value: '',
      required: false,
      order: 3
    },
    cost: {
      type: 'radio',
      label: 'Чи є вхід платним?',
      vars: ['Ні, вхід безкоштовний', 'Так, вхід платний'],
      value: '',
      required: false,
      order: 4
    },
    noise: {
      type: 'radio',
      label: 'Чи шумно там?',
      vars: ['Ні, там тихо', 'Місцями зустрічаюся шумні ділянки', 'Так, там шумно'],
      value: '',
      required: false,
      order: 5
    },
    crowded: {
      type: 'radio',
      label: 'Чи людно там?',
      vars: ['Ні, людей дуже мало', 'Місцями людно', 'Так, людей багацько'],
      value: '',
      required: false,
      order: 6
    },
    long: {
      type: 'radio',
      label: 'Для якої пробіжки це місце найкраще підходить?',
      vars: ['Довгої (8+км)', 'Середньої (5-8км)', 'Короткої (<4км)'],
      value: '',
      required: false,
      order: 7
    },
    surface: {
      type: 'radio',
      label: 'Яке там покриття?',
      vars: ['Прокладене тверде покриття', 'Грунтова дорога', 'По-різному'],
      value: '',
      required: false,
      order: 8
    },
    parking: {
      type: 'radio',
      label: 'Чи є де припаркувати авто неподалік?',
      vars: ['Так, є місце для парковки', 'Ні, немає де залишити авто'],
      value: '',
      required: false,
      order: 9
    },
    cafe: {
      type: 'radio',
      label: 'Чи є якесь місце щоб перекусити неподалік?',
      vars: ['Так, там є де перекусити', 'Ні, поряд немає таких місць'],
      value: '',
      required: false,
      order: 10
    },
    interruption: {
      type: 'radio',
      label: 'Чи може щось перервати або призупинити Вашу пробіжку? Наприклад, світлофори',
      vars: ['Так, на жаль, трапляються перепони', 'Ні, можна бігати без зупинок'],
      value: '',
      required: false,
      order: 11
    },
    safety: {
      type: 'radio',
      label: 'Чи безпечно там бігати?',
      vars: ['Так, цілком безпечно', 'Є речі, які можуть зробити пробіжку небезпечною'],
      value: '',
      required: false,
      order: 12
    },
    dogs: {
      type: 'radio',
      label: 'Чи часто можна зустріти там агресивних собак?',
      vars: ['Так, треба бути обачним', 'Ні, не зустрічав там агресивних собак'],
      value: '',
      required: false,
      order: 13
    },
    water: {
      type: 'radio',
      label: 'Чи є поблизу де придбати воду або вільний доступ до питної води (природнє або рукотворне джерело)?',
      vars: [
        'Можна купити в магазині',
        'Питна вода є у вільному доступі',
        'Є обидва варіанти',
        'Беріть воду з собою'
      ],
      value: '',
      required: false,
      order: 14
    },
    gym: {
      type: 'radio',
      label: 'Чи є там спортивний майданчик?',
      vars: ['Так, є', 'Ні, немає'],
      value: '',
      required: false,
      order: 15
    },
    pollution: {
      type: 'radio',
      label: 'Чи чисте там повітря?',
      vars: ['Так, повітря чисте', 'Ні, повітря важко назвати чистим'],
      value: '',
      required: false,
      order: 16
    },
    hills: {
      type: 'radio',
      label: 'Чи багато підйомів і спусків доведеться зустріти протягом тренування? Якщо так, чи можливо уникнути гірки?',
      vars: ['Так, підйомів багато', 'Підйомів багато, але при бажанні можна їх уникати', 'Ні, маршрут пологий'],
      value: '',
      required: false,
      order: 17
    },
    locationId: {
      type: 'hidden',
      value: props.locationId,
      order: 18
    },
    userId: {
      type: 'hidden',
      value: props.userId,
      order: 19
    },
    feedbackId: {
      type: 'hidden',
      value: props.userId + props.locationId,
      order: 20
    }
  });

  const feedbackHandler = (event) => {
    event.preventDefault();

    const formData = {};
    for (let formElementIdentifier in form) {
      formData[formElementIdentifier] = form[formElementIdentifier].value;
    }
    const newFeedback = {
      feedbackData: formData
    };

    props.onAddFeedback(newFeedback)
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    const updatedFormElement = {
      ...form[inputIdentifier],
      ...{value: event.target.value}
    };
    const updatedLocationForm = {
      ...form,
      ...{[inputIdentifier]: updatedFormElement}
    };
    setForm(updatedLocationForm);
  };

  const formElementsArray = [];
  for (let key in form) {
    formElementsArray.push({
      id: key,
      config: form[key]
    });
  }

  const [ratingChecked, setRatingChecked] = useState();
  const [showFeedbackForm, setShowFeedbackForm] = useState(true);

  const rating = (
    <div className="rating" style={{order: 1}}>
      <div className="stars-rating-input">
        <span onClick={() => setRatingChecked(5)} className={ratingChecked === 5 ? 'checked' : null}>
          <input
            type="radio"
            name="rating"
            id="str5"
            value="5"
            required
            onChange={event => inputChangedHandler(event, 'rating')} />
          <label htmlFor="str5"></label>
        </span>
        <span onClick={() => setRatingChecked(4)} className={ratingChecked === 4 ? 'checked' : null}>
          <input
            type="radio"
            name="rating"
            id="str4"
            value="4"
            required
            onChange={event => inputChangedHandler(event, 'rating')} />
          <label htmlFor="str4"></label>
        </span>
        <span onClick={() => setRatingChecked(3)} className={ratingChecked === 3 ? 'checked' : null}>
          <input
            type="radio"
            name="rating"
            id="str3"
            value="3"
            required
            onChange={event => inputChangedHandler(event, 'rating')} />
          <label htmlFor="str3"></label>
        </span>
        <span onClick={() => setRatingChecked(2)} className={ratingChecked === 2 ? 'checked' : null}>
          <input
            type="radio"
            name="rating"
            id="str2"
            value="2"
            required
            onChange={event => inputChangedHandler(event, 'rating')} />
          <label htmlFor="str2"></label>
        </span>
        <span onClick={() => setRatingChecked(1)} className={ratingChecked === 1 ? 'checked' : null}>
          <input
            type="radio"
            name="rating"
            id="str1"
            value="1"
            required
            onChange={event => inputChangedHandler(event, 'rating')} />
          <label htmlFor="str1"></label>
        </span>
      </div>
    </div>
  );

  const fields = (
    formElementsArray.map(formElement => (
      formElement.id !== 'rating' ?
        <div key={formElement.config.order} style={{order: formElement.config.order}}>
          {formElement.config.type === 'radio' ?
            <div>
              <p>{formElement.config.label}</p>
              {formElement.config.vars.map(variant => (
                <div key={formElement.config.order} style={{order: formElement.config.order}}>
                  <input type="radio"
                         id={variant}
                         name={formElement.id}
                         value={variant}
                         required={formElement.config.required}
                         onChange={event => inputChangedHandler(event, formElement.id)} />
                  <label htmlFor={variant}>{variant}</label>
                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </div>
              ))}
            </div> : null}
        </div>
        : null
    ))
  );

  if (!props.isAuthenticated) {
    props.onSetAuthRedirectPath(props.url)
  }

  const [feedbackExists, setFeedbackExists] = useState(false);
  const checkIfFeedbackExists = () => {
    let queryParams = '?&orderBy="feedbackId"&equalTo="' + props.userId + props.locationId + '"';
    axios.get( '/feedback.json' + queryParams)
      .then(response => {
        if (Object.keys(response.data).length > 0) {
          setFeedbackExists(true)
        }
      } )
      .catch(err => {
        console.log(err);
      } );
  };

  return (
    <div className="add-feedback">
      {props.isAuthenticated ?
        <div>
          {checkIfFeedbackExists()}
          {feedbackExists ? null :
            <div>
              {!showFeedbackForm ?
                <form onSubmit={feedbackHandler}>
                  {rating}
                  {fields}
                  <div className="create-button" style={{order: 21}}>
                    <button className="btn">Залишити відгук</button>
                  </div>
                </form>
                :
                <p className="open-review-message">
                  Бував там?
                  <span onClick={() => setShowFeedbackForm(false)}>Залиш відгук</span>
                </p>
              }
            </div>
          }
        </div> :
        <div>
          <div>
            Щоб залишити відгук необхідно <Link to="/auth">Увійти</Link>
          </div>
        </div>
      }
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddFeedback: (eventData) =>
      dispatch(actions.addFeedback(eventData)),
    onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFeedback);