import Lviv from '../assets/images/cities/lviv.jpg';
import Kyiv from '../assets/images/cities/kyiv.jpg';
import Odessa from '../assets/images/cities/odessa.jpg';

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const getCityImage = (city) => {
  switch(city) {
    case "lviv":
      return Lviv;
    case "kyiv":
      return Kyiv;
    case "odessa":
      return Odessa;
    default:
      break;
  }
};